import React from "react"

import * as MuiIcons from "@mui/icons-material"

import { ReactSVG } from "react-svg"

export default ({ iconName, style }) => {
  const Icon = MuiIcons[iconName]
  if (Icon) {
    return <Icon sx={style} />
  } else {
    if (iconName.toLowerCase().includes("custom")) {
      return <ReactSVG
        src={`/static/icons/${iconName}.svg`}
        style={style}
        wrapper="span"
      />
    } else {
      return iconName
    }
  }
}