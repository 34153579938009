import React, { useState, useEffect, useRef } from "react"
import { connect } from "react-redux"
import { useTranslation } from "react-i18next"

import { useInterval } from "usehooks-ts"

import asyncActions from "./state/async-actions"
import * as actions from "./state/actions"

import { makeStyles } from "@mui/styles"

import {
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AppBar,
  Button,
  Box,
  CircularProgress,
  Divider,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  Paper,
  Snackbar,
  Slider,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Toolbar,
  Popover,
  MenuItem,
  Typography,
  useTheme,
  Input,
  Checkbox,
  Select
} from "@mui/material"

import {
  AddRounded,
  DeleteRounded,
  ExitToAppRounded,
  ExpandMoreRounded,
  RefreshRounded
} from '@mui/icons-material'

import {
  DataGridPro,
  LicenseInfo,
  nlNL
} from "@mui/x-data-grid-pro"

// Hieronder componenten importeren
import HKVDialog from "./components/HKVDialog/6.0"
import HKVDrawer from "./components/HKVDrawer/8.0"
import HKVIcon from "./components/HKVIcon/1.0/HKVIcon"
import HKVVega from "./components/HKVVega/4.0/HKVVega"

import Login from "./components/HKVFirebase/8.0/Authentication/Login"
import { getFirestore, doc, addDoc, setDoc, deleteDoc, collection, getDocs, getDoc, updateDoc, arrayUnion } from "firebase/firestore"

import { doSignOut, onAuthStateChanged, refreshIdToken } from "./components/HKVFirebase/8.0/Authentication/Functions"

import Draggable from "react-draggable"

import i18n from "./i18n"

import moment from "moment"
import "moment/min/locales"

import packageJson from "../package.json"
import "./App.css"
import { forEach } from "vega-lite/build/src/encoding"
import { sortByKey } from "./lib/utils"

const useStyles = makeStyles((theme) => ({
  alertMessage: {
    display: "flex",
    flexDirection: "column"
  },
  root: {
    height: "100%",
    overflow: "auto",
    marginLeft: "60px",
    width: "calc(100% - 60px)",
    padding: theme.spacing(1),
    boxSizing: "border-box",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "40px",
      width: "calc(100% - 40px)",
    }
  },
  menuItem: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(1.5, 0),
    margin: theme.spacing(0, -1),
    cursor: "pointer",
    textAlign: "center",
    background: "unset",
    "&:hover": {
      background: theme.palette.action.hover
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1, 0)
    }
  },
  exitButton: {
    cursor: "pointer",
    color: "#9e9e9e",
    margin: theme.spacing(0.75, -0.25, 0.25),
    "&:hover": {
      color: "#757575"
    }
  },
  accordionRoot: {
    borderRadius: `${theme.shape.borderRadius}px`,
    "&::before": {
      opacity: 0
    }
  },
  accordionExpanded: {
    "&:first-of-type": {
      marginTop: `${theme.spacing(1)} !important`
    }
  },
  accordionSummaryRoot: {
    minHeight: 40,
    padding: theme.spacing(0, 2, 0, 1)
  },
  accordionSummaryContent: {
    justifyContent: "space-between",
    margin: theme.spacing(1, 0)
  },
  accordionDetailsRoot: {
    padding: theme.spacing(0, 1, 1)
  }
}))

function App(props) {
  const { application, database, dispatch, firebase } = props

  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles(theme)

  const [dialogConfigs, setDialogConfigs] = useState({})
  const [drawerStates, setDrawerStates] = useState({})
  const [hasAccess, setHasAccess] = useState()

  const [selectedLanguage, setSelectedLanguage] = useState("nl")

  const [firebaseTokenInterval, setFirebaseTokenInterval] = useState(false)

  const [contentKey, setContentKey] = useState("home")
  const [userName, setUserName] = useState("onbekend")

  const [oefeningen, setOefeningen] = useState([])
  const [nieuweOefening, setNieuweOefening] = useState({})
  const [bewerkOefening, setBewerkOefening] = useState({})
  const [oefeningFilter, setOefeningFilter] = useState("")

  const [schemas, setSchemas] = useState([])
  const [nieuwSchema, setNieuweSchema] = useState({})
  const [bewerkSchema, setBewerkSchema] = useState({})
  const [selectedSchema, setSelectedSchema] = useState({})


  const [waardes, setWaardes] = useState([])
  const [origwaardes, setOrigWaardes] = useState([])

  const [userPreferences, setUserPreferences] = useState({})

  const [vegaRefs, setVegaRefs] = useState({})
  const [vegaOefening, setVegaOefening] = useState({})


  const [pageSize, setPageSize] = useState(25)
  const [expandedAccordion, setExpandedAccordion] = useState()

  const [anchorEl, setAnchorEl] = useState(null)
  const [startLocation, setStartLocation] = useState(null)

  const [average, setAverage] = useState([0, 0, 0, 0, 0, 0, 0, 0])
  const [calculatedAverage, setCalculatedAverage] = useState(0)


  const db = getFirestore()

  const visibilityChanged = (state) => {
    // if (state === "visible") {
    //   getData() // Eenmalig data ophalen
    // }
    // setDataInterval(state === "visible") // Daarna elke x minuten/seconden
  }

  useInterval(() => {
    refreshIdToken(dispatch)
  }, firebaseTokenInterval ? (1000 * 60 * 50) : null) // 50 minuten

  // ComponentDidMount
  useEffect(() => {
    LicenseInfo.setLicenseKey("37a7b8e529ef28d62055125b88e38edfT1JERVI6Mzg3MjYsRVhQSVJZPTE2Nzc2NTkzMDIwMDAsS0VZVkVSU0lPTj0x")

    // Checken of gebruiker is ingelogd/ hier kom je bij elke auth.functie (zoals doSignIn en doSignOut)
    onAuthStateChanged(dispatch, async function (userinfo) {
      // Globale variable zetten zodat we deze kunnen gebruiken bij de fetch functies
      window.AUTH_UID = userinfo && userinfo.uid
      window.AUTH_TOKEN = userinfo && userinfo.token

      setFirebaseTokenInterval(Boolean(userinfo))

      if (Boolean(userinfo)) {
        setHasAccess(true)

        if (userinfo.uid === "UoRDyifJY9MuiYFmhrPcrgAS1332") {
          setUserName("Marc")
        }

        if (userinfo.uid === "fPCQTMLnSeQFcoZPzqaaCyNMndY2") {
          setUserName("Niels")
        }

        getSchemas()
        getOefeningen()

        document.addEventListener("visibilitychange", function () {
          visibilityChanged(document.visibilityState)
        })
      } else {
        setHasAccess(false)
      }
    })
  }, [])

  useEffect(() => {
    if (hasAccess) {
      getPreferences()
    }
  }, [schemas])

  useEffect(() => {
    if (hasAccess) {
      getWaardes()
    }
  }, [oefeningen])

  const refreshData = () => {
    getSchemas()
    getOefeningen()
  }

  const getPreferences = async () => {
    const preferenceSnapshot = await getDocs(collection(db, "userPreferences"));
    preferenceSnapshot.forEach((doc) => {
      if (doc.id === window.AUTH_UID) {
        let preferenceObject = doc.data()
        preferenceObject.id = doc.id;
        setUserPreferences(preferenceObject)
        const schema = schemas.find((schema) => schema.id === preferenceObject.huidigSchema)
        setSelectedSchema(schema)
      }
    })
  }

  const getOefeningen = async () => {
    let oefeningenArray = []
    const oefeningenSnapshot = await getDocs(collection(db, "oefeningen"));
    oefeningenSnapshot.forEach((doc) => {
      let oefeningenObject = doc.data()
      oefeningenObject.id = doc.id;
      oefeningenArray.push(oefeningenObject)
    });
    setOefeningen(oefeningenArray)
  }

  const getSchemas = async () => {
    let schemaArray = []
    const schemaSnapshot = await getDocs(collection(db, "schemas"))

    schemaSnapshot.forEach((doc) => {
      let schemaObject = doc.data()
      schemaObject.id = doc.id
      schemaArray.push(schemaObject)
    });

    setSchemas(schemaArray)
  }

  const getWaardes = async () => {
    let waardesArray = []
    const waardesSnapshot = await getDocs(collection(db, "waardes"));
    waardesSnapshot.forEach((doc) => {
      let waardesObject = doc.data()
      waardesObject.datum = doc.id
      waardesArray.push(waardesObject)
    });

    let transformed = transformWaardes(waardesArray)
    setWaardes(transformed)
  }

  const vegaDidMount = (vegaRef) => {
    dispatch(asyncActions.loaderMin())

    setVegaRefs(vegaRefs => {
      vegaRefs[vegaRef.key] = vegaRef
      return { ...vegaRefs }
    })
  }

  function transformWaardes(inputData) {

    const transformedEntries = [];
    const allEntries = [];

    inputData.flatMap(entry => {
      for (let index = 0; index < Object.keys(entry).length; index++) {

        const userID = Object.keys(entry)[index];

        if (userID != "datum") {

          let persoon = "onbekend"
          if (userID === "UoRDyifJY9MuiYFmhrPcrgAS1332") {
            persoon = "Marc"
          }

          if (userID === "fPCQTMLnSeQFcoZPzqaaCyNMndY2") {
            persoon = "Niels"
          }

          Object.keys(entry[userID]).forEach(oefeningID => {
            let oefeningNaam = lookupOefening(oefeningID).naam;
            const setValues = entry[userID][oefeningID];
            const setArray = Object.keys(setValues).map(setnummer =>
            ({
              [setnummer]: setValues[setnummer]
            }));


            const transformedEntry = {
              datum: entry.datum,
              id: generateUID(16),
              naam: persoon,
              user: userID
            }

            transformedEntry['oefening'] = { id: oefeningID, [oefeningNaam]: setArray };

            const isExist = transformedEntries.some(u => u.oefening.id === oefeningID && u.naam === persoon)
            if (!isExist) {
              transformedEntries.push(transformedEntry);
            }

            allEntries.push(transformedEntry)
            setOrigWaardes(allEntries)
          })
        }
      }
    })

    return transformedEntries;
  }

  const lookupOefening = (id) => {

    let find_oefening = {}
    if (oefeningen && oefeningen.length > 0) {
      find_oefening = oefeningen.find((oefeningObject) => oefeningObject.id === id)

      if (find_oefening && find_oefening.id != null) {
        //ga nu in de waardes checken wat de gemiddelde waarde is
        let average = 0;
        let average_allTime = 0;

        const oefening_waardes = origwaardes.filter((waarde) => waarde.oefening.id == find_oefening.id && waarde.user == window.AUTH_UID)

        if (oefening_waardes && oefening_waardes.length > 0) {

          const lastWaarde = oefening_waardes[oefening_waardes.length - 1]
          let extractedValues = extractOefeningValues(lastWaarde.oefening)

          const waardesArray = Object.values(extractedValues).flatMap(setValues => Object.values(setValues));
          if (waardesArray && waardesArray.length > 0) {
            average = calcAverage(waardesArray)
          }

          let allTimeArray = []
          const datum = getDatum()

          oefening_waardes.forEach(waardeObject => {
            let extractedValues = extractOefeningValues(waardeObject.oefening)

            if (waardeObject.datum == datum) {
              const tmpArray = Object.values(extractedValues).flatMap(setValues => Object.values(setValues));
              find_oefening.vandaag = tmpArray
            }

            const tmpArray = Object.values(extractedValues).flatMap(setValues => Object.values(setValues));
            if (tmpArray && tmpArray.length > 0) {
              tmpArray.forEach(value => {
                allTimeArray.push(value)
              })
            }
          })

          average_allTime = calcAverage(allTimeArray)
        }
        find_oefening.average = average
        find_oefening.average_allTime = average_allTime
      }
    }
    return (find_oefening)
  }

  const generateUID = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const uidArray = [];

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      uidArray.push(characters.charAt(randomIndex));
    }

    return uidArray.join('');
  }

  // Als taal is gewijzigd
  useEffect(() => {
    i18n.changeLanguage(selectedLanguage)
    moment.locale(selectedLanguage)
  }, [selectedLanguage])

  const openDialog = (key, size, options) => {
    const mergedOptions = { ...options, open: true, size: size ? size : "lg" }

    setDialogConfigs(prevState => {
      prevState[key] = mergedOptions
      return { ...prevState }
    })
  }

  const drawerStateChanged = (placement, state) => {
    setDrawerStates(drawerStates => {
      drawerStates[placement] = state
      return { ...drawerStates }
    })
  }

  const closeDialog = (key) => {
    setDialogConfigs(prevState => {
      prevState[key] = null
      return { ...prevState }
    })
  }

  const closeSnackbar = (reason, index) => {
    if (reason === "clickaway") return null

    dispatch(asyncActions.setError({ index: index }))
  }

  const renderLoader = () => {
    if (application.loading > 0) {
      return (
        <div className="loader-background">
          <img rel="preload" as="image" src={`${process.env.PUBLIC_URL}/static/images/loader.gif`} alt="loader" className="loader-default" />
        </div>
      )
    } else return null
  }

  const renderErrors = () => {
    const errors = application.errors.filter(error => error.type === "error" || error.type === "warning")

    return (
      <HKVDialog
        config={{ open: errors.length > 0, size: "small" }}
        onClose={() => props.dispatch(asyncActions.setError({ clear: true }))}
      >
        <DialogTitle>{errors.length > 1 ? "Er zijn meerdere fouten opgetreden" : "Er is een fout opgetreden"}</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column", maxWidh: "100%", maxHeight: 400 }}>
          <div style={{ display: "flex", flexDirection: "column", overflow: "auto" }}>
            {errors.map((error, index) => (
              <div key={index} style={{ border: `1px solid ${theme.palette.grey[300]}`, borderRadius: theme.shape.borderRadius, padding: theme.spacing(0.5, 1), marginTop: index === 0 ? 0 : theme.spacing(0.5) }}>
                <div style={{ display: "flex" }}>
                  <Typography sx={{ marginRight: theme.spacing(1), width: 60 }}>{t("Melding")}:</Typography>
                  <Typography><b>{error.message}</b></Typography>
                </div>
                <div style={{ display: "flex" }}>
                  <Typography sx={{ marginRight: theme.spacing(1), width: 60 }}>{t("Tijdstip")}:</Typography>
                  <Typography><b>{moment(error.key).format("DD-MM HH:mm:ss")}</b></Typography>
                </div>
                {error.type === "error" && <div style={{ display: "flex" }}>
                  <Typography sx={{ marginRight: theme.spacing(1), width: 60 }}>{t("Contact")}:</Typography>
                  <Typography><a href={`mailto:marcjansen89@live.nl?subject=${application.appinfo ? application.appinfo.name : packageJson.name} error&body=Applicatie: ${application.appinfo ? application.appinfo.name : packageJson.name}%0D%0AMelding: ${error.message}%0D%0AFunctie: ${error.function}%0D%0ACode: ${error.number}`}><b>marcjansen89@live.nl</b></a></Typography>
                </div>}
              </div>
            ))}
          </div>
          <div className="dialog-actions">
            <Button
              variant="contained"
              color="primary"
              className="dialog-button"
              onClick={() => props.dispatch(asyncActions.setError({ clear: true }))}
            >
              OK
            </Button>
          </div>
        </DialogContent>
      </HKVDialog>
    )
  }

  const expandAccordion = (event, index) => {
    if (event.target.tagName.toUpperCase() === "BUTTON") { // if clicked on button element don't do anything
      return null
    }
    setExpandedAccordion(expandedAccordion === index ? undefined : index)
  }

  const renderHomeUI = () => {
    return (
      <React.Fragment>
        <Paper sx={{ padding: theme.spacing(0.5), marginBottom: theme.spacing(0.5) }}>
          <Typography variant="h5">Actief schema:</Typography>
          <Select
            value={selectedSchema && selectedSchema.id ? selectedSchema.id : ''}
            onChange={(e) => {
              const selection = schemas.find((schema) => schema.id === e.target.value)
              if (selection != null) {
                setSelectedSchema(selection)
              }
            }}
            size="small"
            sx={{ marginRight: theme.spacing(1) }}
          >
            {schemas && schemas.length > 0
              ? schemas.map((schema, index) => (
                <MenuItem key={index} value={schema.id}>{schema.naam}</MenuItem>
              ))
              : <MenuItem>Geen schema beschikbaar, voeg een schema toe...</MenuItem>
            }
          </Select>
          <Button
            onClick={() => aanpassenUserPreferences("huidigSchema")}
            variant="outlined"
          >
            Maak geselecteerd schema actief
          </Button>
        </Paper>
        <Paper sx={{ padding: theme.spacing(0.5) }}>
          <Typography variant="h5">Schema's:</Typography>
          {selectedSchema && selectedSchema.dagen && selectedSchema.dagen.length > 0
            ? selectedSchema.dagen.map((dag, index) => {
              return (
                <Accordion
                  key={index}
                  expanded={expandedAccordion === index}
                  disableGutters
                  classes={{ expanded: classes.accordionExpanded, root: classes.accordionRoot }}
                  sx={{ width: "100%", marginTop: theme.spacing(1) }}
                >
                  <AccordionSummary
                    classes={{
                      root: classes.accordionSummaryRoot,
                      content: classes.accordionSummaryContent
                    }}
                    expandIcon={<ExpandMoreRounded />}
                    onClick={(event) => expandAccordion(event, index)}
                  >
                    <Typography>{dag.naam} - {`(${dag.oefeningen.length} oefeningen)`}</Typography>
                  </AccordionSummary>
                  <AccordionDetails classes={{ root: classes.accordionDetailsRoot }}>
                    <Typography>Oefeningen: </Typography>
                    {dag.oefeningen.map((oefening, index) => {
                      const find_oefening = lookupOefening(oefening)
                      if (find_oefening != null && find_oefening.naam) {
                        return (
                          <div key={index}>
                            <Typography variant="h6">{find_oefening.naam}</Typography>
                            {find_oefening.sets > 1 ? <Typography variant="caption">{`Vorige keer: ${Math.round(find_oefening.average)} | Historisch: ${Math.round(find_oefening.average_allTime)}`}</Typography> : null}
                            <div>
                              {find_oefening.sets && find_oefening.sets > 0 ? (
                                Array.from({ length: find_oefening.sets }).map((_, setIndex) => (
                                  <React.Fragment>
                                    <Input
                                      onBlur={(e) => { addWaarde(find_oefening.id, e.target.value.replace(/,/g, '.'), setIndex + 1) }}
                                      variant="outlined"
                                      key={setIndex + 1}
                                      placeholder={find_oefening.vandaag ? find_oefening.vandaag[setIndex] : `set ${setIndex + 1}`}>
                                    </Input>
                                    <Paper>

                                    </Paper>
                                  </React.Fragment>
                                ))
                              ) : null}
                            </div>
                          </div>
                        )
                      }
                      else {
                        return (<Typography key={index}>Een verwijderde oefening...</Typography>)
                      }
                    })}

                  </AccordionDetails>
                </Accordion>
              )
            })
            : null}
        </Paper>
      </React.Fragment>
    )
  }

  const renderSchemaUI = () => {
    const columns = [
      {
        headerName: "Naam",
        field: "naam",
        width: 150, minWidth: 150, flex: 1,
        renderCell: (props) => renderCustomCell(props)
      }
    ]
    const rows = schemas ? schemas : []

    return (
      <React.Fragment>
        <Button variant="outlined" sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} onClick={() => openDialog("schemaToevoegen", "md")}>
          <AddRounded></AddRounded>
          Schema Toevoegen
        </Button>
        <DataGridPro
          columns={columns}
          rows={rows}
          headerHeight={46}
          rowHeight={42}
          disableColumnPinning
          disableColumnReorder
          disableColumnResize
          disableColumnSelector
          initialState={{
            sorting: {
              sortModel: [{ field: "naam", sort: "asc" }]
            }
          }}
          sortingOrder={["asc", "desc"]}

          sx={{
            marginTop: theme.spacing(1),
            "& .MuiDataGrid-columnHeaders": {
              background: theme.palette.primary.main,
              color: "white",
              fontSize: theme.typography.pxToRem(15),
              "& .MuiSvgIcon-root": {
                color: "white"
              },
              "& .MuiIconButton-root": {
                color: "white"
              }
            },
            "& .MuiDataGrid-cell": {
              "&:focus": {
                outline: "unset"
              },
              "&:focus-within": {
                outline: "unset"
              }
            },
            "& .MuiDataGrid-row": {
              cursor: "pointer",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.02)"
              }
            },
            "& .MuiDataGrid-row.Mui-selected": {
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.02)"
              }
            },
            "& .MuiDataGrid-footerContainer": {
              height: 40,
              minHeight: 40
            }
          }}

          pageSize={pageSize}
          onRowClick={(e) => {
            setBewerkSchema(schemas.find(schema => schema.id === e.id))
            openDialog("schemaAanpassen", "md")
          }}
          onPageSizeChange={(size) => setPageSize(size)}

          rowsPerPageOptions={rows.length > 100 ? [25, 50, 75, 100, rows.length] : [25, 50, 75, 100]}
          pagination

          localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
        />
      </React.Fragment>
    )
  }

  const renderCustomCell = (props) => {
    const value = props.formattedValue ? props.formattedValue : props.value
    switch (props.field) {
      default:
        return (
          <div className="MuiDataGrid-cellContent">
            <Typography className="ellipsis" variant="body2">{typeof value === "number" || typeof value === "string" ? value : "-"}</Typography>
          </div>
        )
    }
  }

  const renderOefeningUI = () => {
    const columns = [
      {
        headerName: "Naam",
        field: "naam",
        width: 150, minWidth: 150, flex: 1,
        renderCell: (props) => renderCustomCell(props)
      },
      {
        headerName: "Sets",
        field: "sets",
        width: 150, minWidth: 150, flex: 1,
        renderCell: (props) => renderCustomCell(props)
      }
    ]
    const rows = oefeningen ? oefeningen : []

    return (
      <React.Fragment>
        <Button variant="outlined" sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1) }} onClick={() => openDialog("oefeningToevoegen", "md")}>
          <AddRounded></AddRounded>
          Oefening Toevoegen
        </Button>
        <DataGridPro
          columns={columns}
          rows={rows}
          headerHeight={46}
          rowHeight={42}
          disableColumnPinning
          disableColumnReorder
          disableColumnResize
          disableColumnSelector
          initialState={{
            sorting: {
              sortModel: [{ field: "naam", sort: "asc" }]
            }
          }}
          sortingOrder={["asc", "desc"]}

          sx={{
            marginTop: theme.spacing(1),
            "& .MuiDataGrid-columnHeaders": {
              background: theme.palette.primary.main,
              color: "white",
              fontSize: theme.typography.pxToRem(15),
              "& .MuiSvgIcon-root": {
                color: "white"
              },
              "& .MuiIconButton-root": {
                color: "white"
              }
            },
            "& .MuiDataGrid-cell": {
              "&:focus": {
                outline: "unset"
              },
              "&:focus-within": {
                outline: "unset"
              }
            },
            "& .MuiDataGrid-row": {
              cursor: "pointer",
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.02)"
              }
            },
            "& .MuiDataGrid-row.Mui-selected": {
              backgroundColor: "transparent",
              "&:hover": {
                backgroundColor: "rgba(0, 0, 0, 0.02)"
              }
            },
            "& .MuiDataGrid-footerContainer": {
              height: 40,
              minHeight: 40
            }
          }}

          pageSize={pageSize}
          onPageSizeChange={(size) => setPageSize(size)}
          onRowClick={(e) => {
            setBewerkOefening(oefeningen.find(oefening => oefening.id === e.id))
            openDialog("oefeningAanpassen", "md")
          }}
          rowsPerPageOptions={rows.length > 100 ? [25, 50, 75, 100, rows.length] : [25, 50, 75, 100]}
          pagination

          localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
        />
      </React.Fragment>
    )
  }

  const renderDataUI = () => {
    const columns = [
      {
        headerName: "Oefening",
        field: "oefening",
        width: 150, minWidth: 150, flex: 1,
        valueGetter: (params) => {
          const oefeningKeys = Object.keys(params.value);
          const formattedOefening = oefeningKeys[1];
          return formattedOefening
        },

        renderCell: (props) => renderCustomCell(props)
      },
      {
        headerName: "Persoon",
        field: "naam",
        width: 150, minWidth: 150, flex: 1,
        renderCell: (props) => renderCustomCell(props)
      }
    ]

    const rows = waardes ? waardes : []

    return (
      <DataGridPro
        columns={columns}
        rows={rows}
        headerHeight={46}
        rowHeight={42}
        disableColumnPinning
        disableColumnReorder
        disableColumnResize
        disableColumnSelector
        initialState={{
          sorting: {
            sortModel: [{ field: "oefening", sort: "asc" }]
          }
        }}
        sortingOrder={["asc", "desc"]}

        sx={{
          marginTop: theme.spacing(1),
          "& .MuiDataGrid-columnHeaders": {
            background: theme.palette.primary.main,
            color: "white",
            fontSize: theme.typography.pxToRem(15),
            "& .MuiSvgIcon-root": {
              color: "white"
            },
            "& .MuiIconButton-root": {
              color: "white"
            }
          },
          "& .MuiDataGrid-cell": {
            "&:focus": {
              outline: "unset"
            },
            "&:focus-within": {
              outline: "unset"
            }
          },
          "& .MuiDataGrid-row": {
            cursor: "pointer",
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.02)"
            }
          },
          "& .MuiDataGrid-row.Mui-selected": {
            backgroundColor: "transparent",
            "&:hover": {
              backgroundColor: "rgba(0, 0, 0, 0.02)"
            }
          },
          "& .MuiDataGrid-footerContainer": {
            height: 40,
            minHeight: 40
          }
        }}

        pageSize={pageSize}
        onPageSizeChange={(size) => setPageSize(size)}
        onRowClick={(e) => {
          // Extract and display the keys of the "oefening" object
          const oefeningKeys = Object.keys(e.row.oefening);
          const formattedOefening = oefeningKeys[1];
          setVegaOefening({
            persoon: e.row.naam,
            oefening: e.row.oefening.id,
            oefening_naam: formattedOefening,
            waardes: getVegaWaardes(e.row.oefening.id, e.row.naam)
          })
          openDialog("waardesVega", "md")
        }}
        rowsPerPageOptions={rows.length > 100 ? [25, 50, 75, 100, rows.length] : [25, 50, 75, 100]}
        pagination

        localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
      />
    )
  }

  const getVegaWaardes = (oefeningId, persoon) => {
    let vegaArray = []
    origwaardes.map((waarde, index) => {
      if (waarde.oefening.id === oefeningId && waarde.naam === persoon) {
        let extractedValues = extractOefeningValues(waarde.oefening)
        if (extractedValues) {
          let waardesArray = []
          waardesArray = Object.values(extractedValues).flatMap(setValues => Object.values(setValues));
          const average = calcAverage(waardesArray)
          vegaArray.push({ datum: waarde.datum, values: waardesArray, gemiddeld: average })
        }
      }
    })
    // vegaArray = vegaArray.reverse();
    // sort by value
    return sortByKey(vegaArray, 'datum', 'asc')
  }

  const calcAverage = (numbersArray) => {
    if (numbersArray.length === 0) {
      return 0; // Handle the case of an empty array
    }

    const sum = numbersArray.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
    const average = sum / numbersArray.length;

    return average;
  }

  const extractOefeningValues = (oefeningObject) => {
    const values = {};

    // Iterate through the keys of the "oefening" object
    Object.keys(oefeningObject).forEach(key => {
      if (Array.isArray(oefeningObject[key])) {
        const formattedValues = oefeningObject[key].reduce((acc, entry) => {
          const setNumber = Object.keys(entry)[0];
          const setValue = entry[setNumber];
          acc[setNumber] = setValue;
          return acc;
        }, {});

        values[key] = formattedValues;
      }
    });
    return values;
  }

  const renderNotifications = () => {
    const notifications = application.errors.filter(error => error.type === "info" || error.type === "success")

    const height = 50
    return notifications.map((notification, index) => {
      const bottom = (height * index)

      return (
        <Snackbar
          disableWindowBlurListener
          key={`${notification.key}-${index}`}
          open={true}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          autoHideDuration={notification.duration ? notification.duration : 4000}
          onClose={(event, reason) => closeSnackbar(reason, index)}
          onClick={() => closeSnackbar("click", index)}
          sx={{
            zIndex: 99999999,
            width: "fit-content",
            maxWidth: `calc(100% - ${theme.spacing(2)})`,
            height: height,
            left: 8,
            right: 8,
            margin: "0 auto",
            bottom: `calc(${bottom}px + ${theme.spacing(1 * (index + 1))}) !important`
          }}
        >
          <Alert elevation={2} severity={notification.type}>
            <Typography className="ellipsis">
              {notification.message.toString()}
            </Typography>
          </Alert>
        </Snackbar>
      )
    })
  }

  const renderMenuItem = (item) => {
    return (
      <div
        className={classes.menuItem}
        onClick={() => setContentKey(item.key)}
        style={{ cursor: "pointer" }}
      >
        <HKVIcon
          iconName={item.icon}
          style={{
            width: "1em", height: "1em",
            maxWidth: "60%",
            fontSize: theme.typography.pxToRem(30),
            pointerEvents: "none",
            fill: theme.palette.primary.main,
            color: theme.palette.primary.main
          }}
        />
      </div>
    )
  }

  const addSchema = async () => {
    // Add a new document with a generated id.
    const docRef = await addDoc(collection(db, "schemas"), {
      naam: nieuwSchema.naam,
      dagen: nieuwSchema.dagen
    });
    setNieuweSchema({})
    closeDialog('schemaToevoegen')
  }

  const getDatum = () => {
    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = '0' + dd;
    if (mm < 10) mm = '0' + mm;

    const formattedToday = dd + '-' + mm + '-' + yyyy;

    return formattedToday
  }

  const addWaarde = async (oefeningId, waarde, setnummer) => {
    const docRef = doc(db, "waardes", getDatum());
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      const data = docSnap.data();
      const currentObject = data[window.AUTH_UID] || {};
      const currentObjectForOefening = currentObject[oefeningId] || {};

      // Update or add the setnummer with the new waarde
      currentObjectForOefening[setnummer] = Number(waarde);

      // Write the updated object back to the database
      await setDoc(docRef, {
        [window.AUTH_UID]: {
          [oefeningId]: currentObjectForOefening
        }
      }, { merge: true });
    } else {
      // If the document doesn't exist, create it with the new data
      const newData = {
        [window.AUTH_UID]: {
          [oefeningId]: {
            [setnummer]: Number(waarde)
          }
        }
      };

      await setDoc(docRef, newData);
    }
  };

  const aanpassenUserPreferences = async (type) => {
    switch (type) {
      case "huidigSchema":
        const docRef = await setDoc(doc(db, "userPreferences", window.AUTH_UID), {
          huidigSchema: selectedSchema.id
        });
        break;

      default:
        break;
    }
    getPreferences()
  }

  const aanpassenSchema = async () => {
    const docRef = await setDoc(doc(db, "schemas", bewerkSchema.id), {
      naam: bewerkSchema.naam,
      dagen: bewerkSchema.dagen
    });
    setBewerkSchema({})
    getSchemas()
    closeDialog('schemaAanpassen')
  }

  const deleteSchema = async () => {
    // Add a new document with a generated id.
    const docRef = await deleteDoc(doc(db, "schemas", bewerkSchema.id), {});
    setBewerkSchema({})
    getSchemas()
    closeDialog('schemaAanpassen')
  }

  const addOefening = async () => {
    // Add a new document with a generated id.
    const docRef = await addDoc(collection(db, "oefeningen"), {
      naam: nieuweOefening.naam,
      sets: nieuweOefening.sets
    });
    setNieuweOefening({})
    getOefeningen()
    closeDialog('oefeningToevoegen')
  }

  const aanpassenOefening = async () => {
    const docRef = await setDoc(doc(db, "oefeningen", bewerkOefening.id), {
      naam: bewerkOefening.naam,
      sets: bewerkOefening.sets
    });
    setBewerkOefening({})
    getOefeningen()
    closeDialog('oefeningAanpassen')
  }

  const deleteOefening = async () => {
    // Add a new document with a generated id.
    const docRef = await deleteDoc(doc(db, "oefeningen", bewerkOefening.id), {});
    setBewerkOefening({})
    getOefeningen()
    closeDialog('oefeningAanpassen')
  }

  const renderNieuwSchemaDag = (dag, index) => {
    return (
      <Paper key={index}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Input value={dag.naam} placeholder="Naam" onChange={(e) => {
            setNieuweSchema(prevState => {
              prevState.dagen[index].naam = e.target.value
              return { ...prevState }
            })
          }}>
          </Input>
          <Button onClick={() => {
            const dagenArray = nieuwSchema.dagen.toSpliced(index, 1)
            setNieuweSchema(prevState => {
              prevState.dagen = dagenArray
              return { ...prevState }
            })
          }}>
            <DeleteRounded></DeleteRounded>
          </Button>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Input placeholder="Filter oefeningen..." value={oefeningFilter} onChange={(e) => { setOefeningFilter(e.target.value) }}></Input>
          {oefeningen && oefeningen.length > 0
            ?
            oefeningen.map((oefening, checkIndex) => {
              if (oefening.naam.toLowerCase().includes(oefeningFilter.toLowerCase())) {
                return (
                  <div key={checkIndex} style={{ display: "flex", flexDirection: "row" }}>
                    <Checkbox value={dagHeeftOefening(dag, oefening.id)} defaultChecked={dagHeeftOefening(dag, oefening.id)} onChange={(e) => {
                      if (e.target.checked) {
                        setNieuweSchema(prevState => {
                          {
                            prevState.dagen[index].oefeningen && prevState.dagen[index].oefeningen.length > 0
                              ? prevState.dagen[index].oefeningen.push(oefening.id)
                              : prevState.dagen[index].oefeningen = [oefening.id]
                          }
                          return { ...prevState }
                        })
                      }
                      else {
                        setNieuweSchema(prevState => {
                          const newArray = [...prevState.dagen[index].oefeningen.filter((dagOefening) => dagOefening != oefening.id)]
                          prevState.dagen[index].oefeningen = newArray
                          return { ...prevState }
                        })
                      }
                    }}>{oefening.naam}</Checkbox>
                    <Typography>{oefening.naam} ({oefening.sets} sets)</Typography>
                  </div>)
              }
            })
            : <Typography>Voeg eerst oefeningen toe...</Typography>}
        </div>
      </Paper>
    )
  }

  const renderBewerkSchemaDag = (dag, index) => {
    return (
      <Paper key={index}>
        <div style={{ display: "flex", flexDirection: "row" }}>
          <Input value={dag.naam} placeholder="Naam" onChange={(e) => {
            setBewerkSchema(prevState => {
              prevState.dagen[index].naam = e.target.value
              return { ...prevState }
            })
          }}>
          </Input>
          <Button onClick={() => {
            const dagenArray = bewerkSchema.dagen.toSpliced(index, 1)
            setBewerkSchema(prevState => {
              prevState.dagen = dagenArray
              return { ...prevState }
            })
          }}>
            <DeleteRounded ></DeleteRounded>
          </Button>
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <Input placeholder="Filter oefeningen..." value={oefeningFilter} onChange={(e) => { setOefeningFilter(e.target.value) }}></Input>
          {oefeningen && oefeningen.length > 0
            ?
            oefeningen.map((oefening, checkIndex) => {
              if (oefening.naam.toLowerCase().includes(oefeningFilter.toLowerCase())) {
                return (
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <Checkbox key={checkIndex} value={dagHeeftOefening(dag, oefening.id)} defaultChecked={dagHeeftOefening(dag, oefening.id)} onChange={(e) => {
                      if (e.target.checked) {
                        setBewerkSchema(prevState => {
                          {
                            prevState.dagen[index].oefeningen && prevState.dagen[index].oefeningen.length > 0
                              ? prevState.dagen[index].oefeningen.push(oefening.id)
                              : prevState.dagen[index].oefeningen = [oefening.id]
                          }
                          return { ...prevState }
                        })
                      }
                      else {
                        setBewerkSchema(prevState => {
                          const newArray = [...prevState.dagen[index].oefeningen.filter((dagOefening) => dagOefening != oefening.id)]
                          prevState.dagen[index].oefeningen = newArray
                          return { ...prevState }
                        })
                      }
                    }}>{oefening.naam}</Checkbox>
                    <Typography>{oefening.naam} ({oefening.sets} sets)</Typography>
                  </div>)
              }
            })
            : <Typography>Voeg eerst oefeningen toe...</Typography>}
        </div>
      </Paper>
    )
  }

  const dagHeeftOefening = (dag, oefening) => {
    if (dag.oefeningen && dag.oefeningen.length > 0) {
      if (dag.oefeningen.filter(dagoefening => dagoefening == oefening).length > 0) {
        return true;
      }
      else {
        return false;
      }
    }
    else {
      return false;
    }
  }

  const startClick = (event) => {
    const rect = event.target.getBoundingClientRect()
    const startXY = { x: rect.x, y: rect.y }
    setStartLocation(startXY)
  }

  const endClick = (event) => {
    const rect = event.target.getBoundingClientRect()
    const endXY = { x: rect.x, y: rect.y }
    if (endXY.x === startLocation.x && endXY.y === startLocation.y) {
      handleClick(event)
    }
  }

  const handleClick = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderFloatingAverageCalculator = () => {

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
      <React.Fragment>
        <Draggable
          onStart={(e) => startClick(e)}
          onStop={(e) => endClick(e)}>
          <Button variant="contained">
            Gemiddelde berekenen
          </Button>
        </Draggable >
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Paper>
            <Typography>{calculatedAverage}</Typography>
            <Input type="number"
              onChange={(e) => {
                setAverage(prevState => {
                  prevState.splice(0, 0, Number(e.target.value))
                  return [...prevState]
                })
              }}
              onBlur={(e) => {
                let calced = calcAverage(average)
                setCalculatedAverage(calced)
              }}>
            </Input>
            <Input type="number"></Input>
            <Input type="number"></Input>
            <Input type="number"></Input>
            <Input type="number"></Input>
            <Input type="number"></Input>
            <Input type="number"></Input>
            <Input type="number"></Input>
          </Paper>
        </Popover>
      </React.Fragment>

    )
  }

  const renderContent = () => {
    switch (contentKey) {
      case "schema":
        return renderSchemaUI()

      case "oefening":
        return renderOefeningUI()

      case "data":
        return renderDataUI()

      case "home":
        return renderHomeUI()
      default:
        return;
    }
  }

  const items = [
    {
      name: "Home",
      key: "home",
      icon: "HomeRounded",
    },
    {
      name: "Schemas",
      key: "schema",
      icon: "InventoryRounded",
    },
    {
      name: "Oefeningen",
      key: "oefening",
      icon: "FitnessCenterRounded",
    },
    {
      name: "Data",
      key: "data",
      icon: "LeaderboardRounded",
    }
  ]

  // Hier kom je bij inladen van viewer, tijdens dat gebruiker wordt ge authenticeerd, ook als hij niet ingelogd is
  if (hasAccess === undefined) {
    return null
  }

  // Als gebruiker niet is ingelogd
  // Als gebruiker geen rechten heeft tot de viewer
  if (!firebase.userinfo || !hasAccess) {
    return (
      <div className="App">
        {renderLoader()}
        {renderErrors()}
        {renderNotifications()}
        <Login
          dispatch={dispatch}
          firebaseUser={firebase.userinfo}
        />
      </div>
    )
  }

  return (
    <div className="App">
      {renderLoader()}
      {renderErrors()}
      {renderNotifications()}

      <div className={classes.root}>
        {renderContent()}
        <Button
          variant="contained"
          style={{ marginBottom: theme.spacing(1) }}
          fullWidth
          onClick={refreshData}
          startIcon={<RefreshRounded />}
        >
          Ververs Data
        </Button>
        {/* {renderFloatingAverageCalculator()} */}
      </div>

      <HKVDrawer
        header="img" placement="left-mini" toggleButton={false} variant="persistent" defaultOpen={true} image="static/images/logo-48.png"
        onToggle={drawerStateChanged}
      >
        <div style={{ display: "flex", flexDirection: "column", marginTop: theme.spacing(1), flexGrow: 1 }}>
          {items.map((item, index) => {
            return (
              <Tooltip disableInteractive key={index} placement="right" title={item.name} sx={{ marginTop: theme.spacing(-0.25), marginLeft: 0 }}>
                {renderMenuItem(item)}
              </Tooltip>
            )
          })}
        </div>
        {firebase.userinfo ? <React.Fragment>
          <Tooltip title="Loguit" placement="right" enterDelay={0} leaveDelay={0}>
            <div
              className={classes.exitButton}
              style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
              onClick={() => openDialog("signout", "small")}
            >
              <ExitToAppRounded fontSize="large" sx={{ maxWidth: "100%" }} />
            </div>
          </Tooltip>
        </React.Fragment> : null}
      </HKVDrawer>

      {dialogConfigs.oefeningToevoegen && <HKVDialog config={dialogConfigs.oefeningToevoegen} onClose={() => closeDialog("oefeningToevoegen")}>
        <DialogTitle>Voeg een oefening toe</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <Typography>Voeg een oefening toe zodat deze beschikbaar wordt in Schemas en Data</Typography>
          <Input
            value={nieuweOefening.naam}
            placeholder="Naam"
            onChange={(e) => {
              setNieuweOefening(prevState => {
                prevState.naam = e.target.value
                return { ...prevState }
              })
            }}>
          </Input>
          <Input
            value={nieuweOefening.sets}
            placeholder="Sets"
            onChange={(e) => {
              setNieuweOefening(prevState => {
                prevState.sets = e.target.value
                return { ...prevState }
              })
            }}>
          </Input>
          <div className="dialog-actions" style={{ margin: theme.spacing(1, 0, 0) }}>
            <Button color="primary" disabled={(nieuweOefening.naam != null && nieuweOefening.naam.length > 0) && (nieuweOefening.sets != null && nieuweOefening.sets > 0) ? false : true} variant="contained" className="dialog-button" onClick={() => { addOefening() }}>
              Opslaan
            </Button>
            <Button color="primary" variant="contained" className="dialog-button" onClick={() => closeDialog("oefeningToevoegen")}>
              Sluiten
            </Button>
          </div>
        </DialogContent>
      </HKVDialog>}

      {dialogConfigs.oefeningAanpassen && <HKVDialog config={dialogConfigs.oefeningAanpassen} onClose={() => closeDialog("oefeningAanpassen")}>
        <DialogTitle>{`${bewerkOefening.naam} aanpassen`}</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <div>
            <Input placeholder="Naam" value={bewerkOefening.naam && bewerkOefening.naam.length > 0 ? bewerkOefening.naam : ""} onChange={(e) => {
              setBewerkOefening(prevState => {
                prevState.naam = e.target.value
                return { ...prevState }
              })
            }}>
            </Input>
            <Button onClick={() => {
              deleteOefening()
            }}>
              <DeleteRounded >
              </DeleteRounded>
            </Button>
          </div>
          <Input
            value={bewerkOefening.sets}
            placeholder="Sets"
            onChange={(e) => {
              setBewerkOefening(prevState => {
                prevState.sets = e.target.value
                return { ...prevState }
              })
            }}>
          </Input>
          <div className="dialog-actions" style={{ margin: theme.spacing(1, 0, 0) }}>
            <Button color="primary" disabled={(bewerkOefening.naam != null && bewerkOefening.naam.length > 0) && (bewerkOefening.sets != null && bewerkOefening.sets > 0) ? false : true} variant="contained" className="dialog-button" onClick={() => aanpassenOefening()}>
              Opslaan
            </Button>
            <Button color="primary" variant="contained" className="dialog-button" onClick={() => closeDialog("oefeningAanpassen")}>
              Sluiten
            </Button>
          </div>
        </DialogContent>
      </HKVDialog>}

      {dialogConfigs.schemaToevoegen && <HKVDialog config={dialogConfigs.schemaToevoegen} onClose={() => closeDialog("schemaToevoegen")}>
        <DialogTitle>Voeg een Schema toe</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <div>
            <Input placeholder="Schema naam" value={nieuwSchema.naam && nieuwSchema.naam.length > 0 ? nieuwSchema.naam : ""} onChange={(e) => {
              setNieuweSchema(prevState => {
                prevState.naam = e.target.value
                return { ...prevState }
              })
            }}>
            </Input>
            <Button onClick={() => {
              setNieuweSchema({})
              closeDialog("schemaToevoegen")
            }}>
              <DeleteRounded >
              </DeleteRounded>
            </Button>
          </div>
          <Button variant="contained" style={{ width: "200px" }} onClick={() => {
            setNieuweSchema(prevState => {
              prevState.dagen ?
                prevState.dagen.push({ naam: "", oefeningen: [] })
                :
                prevState.dagen = [{ naam: "", oefeningen: [] }]
              return { ...prevState }
            })
          }}>
            Dag<AddRounded></AddRounded>
          </Button>
          <div style={{ display: "flex", flexDirection: "row", width: "100%", flexWrap: "wrap", justifyContent: "space-between" }}>
            {nieuwSchema.dagen && nieuwSchema.dagen.length > 0
              ? nieuwSchema.dagen.map((dag, index) => {
                return (
                  renderNieuwSchemaDag(dag, index)
                )
              })
              : null}
          </div>
          <div className="dialog-actions" style={{ margin: theme.spacing(1, 0, 0) }}>
            <Button color="primary" disabled={(nieuwSchema.naam != null && nieuwSchema.naam.length > 0) && (nieuwSchema.dagen != null && nieuwSchema.dagen.length > 0) ? false : true} variant="contained" className="dialog-button" onClick={() => addSchema()}>
              Opslaan
            </Button>
            <Button color="primary" variant="contained" className="dialog-button" onClick={() => closeDialog("schemaToevoegen")}>
              Sluiten
            </Button>
          </div>
        </DialogContent>
      </HKVDialog>}

      {dialogConfigs.waardesVega && <HKVDialog config={dialogConfigs.waardesVega} onClose={() => closeDialog("waardesVega")}>
        <DialogTitle>{`${vegaOefening.persoon} | ${vegaOefening.oefening_naam}`}</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <div style={{ width: "90%", height: "75%", marginBottom: theme.spacing(2) }}>
            <HKVVega
              config={{ specName: "oefeningen", key: "vega", localData: vegaOefening }}
              dispatch={dispatch}
              vegaDidMount={vegaDidMount}
            />
          </div>

          <div className="dialog-actions" style={{ margin: theme.spacing(1, 0, 0) }}>
            <Button color="primary" variant="contained" className="dialog-button" onClick={() => closeDialog("waardesVega")}>
              Sluiten
            </Button>
          </div>
        </DialogContent>
      </HKVDialog>}

      {dialogConfigs.schemaAanpassen && <HKVDialog config={dialogConfigs.schemaAanpassen} onClose={() => closeDialog("schemaAanpassen")}>
        <DialogTitle>{`${bewerkSchema.naam} aanpassen`}</DialogTitle>
        <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
          <div>
            <Input placeholder="Schema naam" value={bewerkSchema.naam && bewerkSchema.naam.length > 0 ? bewerkSchema.naam : ""} onChange={(e) => {
              setBewerkSchema(prevState => {
                prevState.naam = e.target.value
                return { ...prevState }
              })
            }}>
            </Input>
            <Button onClick={() => {
              deleteSchema()
            }}>
              <DeleteRounded >
              </DeleteRounded>
            </Button>
          </div>
          <Button variant="contained" style={{ width: "200px" }} onClick={() => {
            setBewerkSchema(prevState => {
              prevState.dagen ?
                prevState.dagen.push({ naam: "", oefeningen: [] })
                :
                prevState.dagen = [{ naam: "", oefeningen: [] }]
              return { ...prevState }
            })
          }}>
            Dag<AddRounded></AddRounded>
          </Button>
          <div style={{ display: "flex", flexDirection: "row", width: "100%", flexWrap: "wrap", justifyContent: "space-between" }}>
            {bewerkSchema.dagen && bewerkSchema.dagen.length > 0
              ? bewerkSchema.dagen.map((dag, index) => {
                return (
                  renderBewerkSchemaDag(dag, index)
                )
              })
              : null}
          </div>
          <div className="dialog-actions" style={{ margin: theme.spacing(1, 0, 0) }}>
            <Button color="primary" disabled={(bewerkSchema.naam != null && bewerkSchema.naam.length > 0) && (bewerkSchema.dagen != null && bewerkSchema.dagen.length > 0) ? false : true} variant="contained" className="dialog-button" onClick={() => aanpassenSchema()}>
              Opslaan
            </Button>
            <Button color="primary" variant="contained" className="dialog-button" onClick={() => closeDialog("schemaAanpassen")}>
              Sluiten
            </Button>
          </div>
        </DialogContent>
      </HKVDialog>}


      <HKVDialog config={dialogConfigs.signout} onClose={() => closeDialog("signout")}>
        <DialogTitle>{t("Loguit_titel")}</DialogTitle>
        <DialogContent sx={{ flexDirection: "column" }}>
          <div className="dialog-actions" style={{ margin: 0 }}>
            <Button color="inherit" variant="outlined" className="dialog-button" onClick={() => closeDialog("signout")}>
              {t("Nee")}
            </Button>
            <Button color="primary" variant="contained" className="dialog-button" onClick={() => {
              closeDialog("signout")
              doSignOut(dispatch)
            }}>
              {t("Ja")}
            </Button>
          </div>
        </DialogContent>
      </HKVDialog>
    </div>
  )
}

export default connect(state => state)(App)