import { auth } from "./firebase"
import firebase from "firebase/compat/app"

import {
  getUrl,
  getWebService
} from "../../../../state/async-actions/mangrove"

import * as actions from "../../../../state/actions"
import asyncActions from "../../../../state/async-actions"

import moment from "moment"

// Account aanmaken met email en wachtwoord
export function doCreateUserWithEmailAndPassword(appid, email, name, password, dispatch, callback) {
  dispatch(asyncActions.loaderPlus())
    .then(() => {
      auth.createUserWithEmailAndPassword(email, password)
        .then(function (firebaseUser) {
          if (firebaseUser) {
            firebaseUser.user.updateProfile({
              displayName: name
            })
              .then(function () {
                const userinfo = {
                  uid: firebaseUser.user.uid,
                  token: firebaseUser.user.auth.currentUser.accessToken,
                  name: name,
                  email: firebaseUser.user.email,
                  isVerified: true
                }

                if (callback) {
                  callback(userinfo)
                } else {
                  dispatch(asyncActions.loaderMin())
                }

                dispatch({ type: actions.SET_AUTH_USER, userinfo: userinfo })
              })
          }
        })
        .catch(function (error) {
          dispatch(asyncActions.loaderMin())
          dispatch(asyncActions.setError({ message: error.code ? error.code : error, type: "error" }))
        })
    })
}

// Wachtwoord resetten
export function doPasswordReset(email, dispatch) {
  auth.sendPasswordResetEmail(email)
    .then(function (firebaseUser) {
      dispatch(asyncActions.setError({ message: "Er is een e-mail verzonden naar het opgegeven account", type: "info" }))
    })
    .catch(function (error) {
      dispatch(asyncActions.setError({ message: error.code ? error.code : error, type: "error" }))
    })
}

// Opnieuw authenticeren
export async function doReAuthenticate(userinfo, dispatch, callback) {
  const currentUser = auth.currentUser
  const credentials = firebase.auth.EmailAuthProvider.credential(userinfo.email, userinfo.password)

  currentUser.reauthenticateWithCredential(credentials)
    .then((firebaseUser) => {
      const MFAs = firebaseUser.user.multiFactor.enrolledFactors

      auth.currentUser.getIdTokenResult(true)
        .then((idTokenResult) => {
          const userinfo = {
            uid: firebaseUser.user.uid,
            token: idTokenResult.token,
            name: firebaseUser.user.displayName,
            email: firebaseUser.user.email,
            apps: idTokenResult.claims.apps,
            isVerified: idTokenResult.claims.isVerified,
            isDemoAccount: idTokenResult.claims.isDemoAccount,
            MFAs: MFAs
          }

          dispatch(asyncActions.setError({ message: "Reauth_successful", type: "success" }))
          dispatch({ type: actions.SET_AUTH_USER, userinfo: userinfo })

          if (callback) {
            callback(userinfo)
          }
        })
    })
    .catch((error) => {
      dispatch(asyncActions.setError({ message: error.code ? error.code : error, type: "error" }))
    })
}

// Anoniem inloggen
export function doSignInAnonymously(dispatch, callback) {
  auth.signInAnonymously()
    .then(function (firebaseUser) {
      if (firebaseUser) {
        const userinfo = {
          uid: firebaseUser.user.uid,
          token: firebaseUser.auth.currentUser.accessToken
        }

        if (callback) {
          callback(userinfo)
        }

        dispatch({ type: actions.SET_AUTH_USER, userinfo: userinfo })
      }
    })
    .catch(function (error) {
      dispatch(asyncActions.setError({ message: error.code ? error.code : error, type: "error" }))
    })
}

// Uitloggen
export function doSignOut(dispatch, recaptchaVerifier, setRecaptchaVerifier) {
  // Als een gebruiker MFA methode(s) heeft reset de recaptcha anders kan je niet weer direct inloggen
  if (auth.currentUser && auth.currentUser.multiFactor.enrolledFactors.length > 0) {
    if (recaptchaVerifier) {
      grecaptcha.reset(recaptchaVerifier._delegate.widgetId)
      setRecaptchaVerifier(recaptchaVerifier)
    }
  }

  auth.signOut()
    .then(() => {
      // Niks, bij de auth.signOut() kom je direct bij de "authStateChanged"
    })
    .catch(function (error) {
      dispatch(asyncActions.setError({ message: error.code ? error.code : error, type: "error" }))
    })
}

// Verificatiemail sturen
export function doSendVerify(dispatch) {

  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    url: 'http://localhost:3000',
    // This must be true.
    handleCodeInApp: true,
  }
  console.log('auth.currentUser', auth.currentUser.email)
  auth.sendSignInLinkToEmail(auth.currentUser.email, actionCodeSettings)
  .then(() => {
    dispatch(asyncActions.setError({ message: "Er is een e-mail verzonden naar het opgegeven account", type: "info" }))
  })
  .catch((error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    dispatch(asyncActions.setError({ message: errorMessage, type: "error" }))

  });

}

// Forceer refresh van firebase token (Deze verloopt na 60minuten, refresht automatisch met gebruik van een firestore)
export function refreshIdToken(dispatch, callback) {
  try {
    auth.currentUser.getIdTokenResult(true)
      .then((idTokenResult) => {
        const newToken = idTokenResult.token
        window.AUTH_TOKEN = newToken

        const MFAs = auth.currentUser.multiFactor.enrolledFactors
        const userinfo = {
          uid: auth.currentUser.uid,
          token: newToken,
          name: auth.currentUser.displayName,
          email: auth.currentUser.email,
          apps: idTokenResult.claims.apps,
          isVerified: idTokenResult.claims.isVerified,
          isDemoAccount: idTokenResult.claims.isDemoAccount,
          MFAs: MFAs
        }

        dispatch({ type: actions.SET_AUTH_USER, userinfo: userinfo })

        if (callback) {
          callback(userinfo)
        }
      })
  } catch (error) {
    refreshIdToken(dispatch, callback)
  }
}

// observer van firebase authenticatie
export function onAuthStateChanged(dispatch, callback) {
  auth.onAuthStateChanged((firebaseUser) => {
    let userinfo = null

    if (firebaseUser) {
      const MFAs = auth.currentUser.multiFactor.enrolledFactors

      auth.currentUser.getIdTokenResult()
        .then((idTokenResult) => {
          userinfo = {
            uid: firebaseUser.uid,
            token: firebaseUser.auth.currentUser.accessToken,
            name: firebaseUser.displayName,
            email: firebaseUser.email,
            apps: idTokenResult.claims.apps,
            isVerified: idTokenResult.claims.isVerified,
            isDemoAccount: idTokenResult.claims.isDemoAccount,
            MFAs: MFAs
          }

          dispatch({ type: actions.SET_AUTH_USER, userinfo: userinfo })

          if (callback) {
            callback(userinfo)
          }
        })
    } else {
      dispatch({ type: actions.SET_AUTH_USER, userinfo: userinfo })

      if (callback) {
        callback(userinfo)
      }
    }
  })
}

export function doRegisterMFA(tel, dispatch, recaptchaVerifier, setRecaptchaVerifier, setReAuthenticate, callback) {
  let verifier = recaptchaVerifier

  if (!recaptchaVerifier) {
    const newVerifier = new firebase.auth.RecaptchaVerifier("signin-recaptcha-container", {
      size: "invisible"
    })
    verifier = newVerifier

    setRecaptchaVerifier(newVerifier)
  }

  auth.currentUser.multiFactor.getSession()
    .then((multiFactorSession) => {
      // Specify the phone number and pass the MFA session.
      const phoneInfoOptions = {
        phoneNumber: tel,
        session: multiFactorSession
      }
      const phoneAuthProvider = new firebase.auth.PhoneAuthProvider()
      // Send SMS verification code.

      return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, verifier)
    })
    .then((verificationId) => {
      // Ask user for the SMS verification code.
      // LoaderMin in callback
      callback(verificationId)
    })
    .catch(function (error) {
      // Als je MFA wilt registreren moet je recent zijn ingelogd, als we deze error krijgen moet de gebruiker eerst opnieuw authentiseren
      if (error.code === "auth/requires-recent-login") {
        setReAuthenticate(true)
      } else {
        dispatch(asyncActions.setError({ message: error.code ? error.code : error, type: "error", duration: 6000 }))
      }
    })
}

export function doVerifyMFA(resolver, verificationId, token, dispatch, callback) {
  const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, token)
  const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred)

  // Complete sign-in.
  dispatch(asyncActions.loaderPlus())
    .then(() => {
      return resolver.resolveSignIn(multiFactorAssertion)
        .then(function (userCredential) {
          // User successfully signed in with the second factor phone number.
          // LoaderMin in callback
          callback(userCredential)
        })
        .catch(function (error) {
          dispatch(asyncActions.loaderMin())
          dispatch(asyncActions.setError({ message: error.code ? error.code : error, type: "error" }))
        })
    })
}

export function doVerifyMFARegistration(verificationId, token, dispatch, callback) {
  const cred = firebase.auth.PhoneAuthProvider.credential(verificationId, token)
  const multiFactorAssertion = firebase.auth.PhoneMultiFactorGenerator.assertion(cred)

  // Complete enrollment. This will update the underlying tokens
  // and trigger ID token change listener.
  dispatch(asyncActions.loaderPlus())
    .then(() => {
      return auth.currentUser.multiFactor.enroll(multiFactorAssertion, "Mangrove user phone number")
        .then(() => {
          const MFAs = auth.currentUser.multiFactor.enrolledFactors

          auth.currentUser.getIdTokenResult(true)
            .then((idTokenResult) => {
              const userinfo = {
                uid: auth.currentUser.uid,
                token: idTokenResult.token,
                name: auth.currentUser.displayName,
                email: auth.currentUser.email,
                apps: idTokenResult.claims.apps,
                isVerified: idTokenResult.claims.isVerified,
                isDemoAccount: idTokenResult.claims.isDemoAccount,
                MFAs: MFAs
              }

              if (callback) {
                callback(userinfo)
              }

              dispatch(asyncActions.loaderMin())
              dispatch(asyncActions.setError({ message: "MFA Enrollment successfull", type: "success" }))
              dispatch({ type: actions.SET_AUTH_USER, userinfo: userinfo })
            })
        })
        .catch(function (error) {
          dispatch(asyncActions.loaderMin())
          dispatch(asyncActions.setError({ message: error.code ? error.code : error, type: "error" }))
        })
    })
}

export function doSendMFA(resolver, selectedMFA, dispatch, recaptchaVerifier, setRecaptchaVerifier, callback) {
  dispatch(asyncActions.loaderPlus())
    .then(() => {
      let verifier = recaptchaVerifier

      if (!recaptchaVerifier) {
        const newVerifier = new firebase.auth.RecaptchaVerifier("signin-recaptcha-container", {
          size: "invisible"
        })
        verifier = newVerifier

        setRecaptchaVerifier(newVerifier)
      }

      if (selectedMFA === firebase.auth.PhoneMultiFactorGenerator.FACTOR_ID) {
        const multiFactorWildHunt = resolver.hints.find(hint => hint.factorId === selectedMFA)

        var phoneInfoOptions = {
          multiFactorHint: multiFactorWildHunt,
          session: resolver.session
        }

        var phoneAuthProvider = new firebase.auth.PhoneAuthProvider()
        // Send SMS verification code
        return phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, verifier)
          .then(function (verificationId) {
            // Ask user for the SMS verification code.
            // LoaderMin in callback
            callback(verificationId)
          })
          .catch(function (error) {
            dispatch(asyncActions.loaderMin())
            dispatch(asyncActions.setError({ message: error.code ? error.code : error, type: "error" }))
          })
      } else {
        dispatch(asyncActions.loaderMin())
        dispatch(asyncActions.setError({ message: "MFA_not_supported", type: "error" }))
      }
    })
}

export function doSignInWithEmailAndPassword(email, password, dispatch, callback) {
  auth.signInWithEmailAndPassword(email, password)
    .then(function (firebaseUser) {
      // Niks, bij de auth.signInWithEmailAndPassword kom je direct bij de "authStateChanged"
    })
    .catch(function (error) {
      var errorCode = error.code
      var errorMessage = error.message

      if (error.code == "auth/multi-factor-auth-required") {
        const resolver = error.resolver
        // The user is a multi-factor user. Second factor challenge is required.

        callback(resolver)
      } else {
        dispatch(asyncActions.setError({ message: error.code ? error.code : error, type: "error" }))
        // Unsupported second factor.
        // Note that only phone second factors are currently supported.
      }
    })
}