import moment from "moment"

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function checkForTooltip(event, id) {
  const element = document.getElementById(id)

  // Checken of ellipsis is toegepast op de typography, zo ja toon de tooltip anders niet
  if (element && event.target.offsetWidth < event.target.scrollWidth) {
  } else {
    element.style.display = "none"
  }
}

export function exportJsonToCSV(json, filename) {
  if (json) {
    let CSV = ""
    let row = ""

    // This loop will extract the label from 1st index of on array
    for (const index in json[0]) {
      // Now convert each value to string and comma-seprated
      const upper = index.replace(/^\w/, c => c.toUpperCase())
      row += upper + ","
    }

    row = row.slice(0, -1)
    // append Label row with line break
    CSV += row + "\r\n"

    for (let i = 0; i < json.length; i++) {
      row = ""

      // 2nd loop will extract each column and convert it in string comma-seprated
      for (const index in json[i]) {
        if (index === "datetime") {
          row += "\"" + moment(json[i][index]).format("DD-MM-YYYY HH:mm") + "\","
        } else if (index === "periodlength") {
          const formatted = `${Math.round(moment.duration(json[i][index]).asDays())}d
          ${moment(json[i][index]).utc().format("H")}h
          ${moment(json[i][index]).utc().format("mm")}m`

          row += "\"" + formatted + "\","

        } else {
          row += "\"" + json[i][index] + "\","
        }
      }

      row.slice(0, row.length - 1)

      // add a line break after each row
      CSV += row + "\r\n"
    }

    const blob = new Blob([CSV], { type: "text/csv;charset=utf-8;" })

    const link = document.createElement("a")
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob)
      link.setAttribute("href", url)
      link.setAttribute("download", filename)
      link.style.visibility = "hidden"
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    }
  }
}

export function filterByKey(data, key) {
  return Object.values(
    data.reduce((c, e) => {
      if (!c[e[key]]) c[e[key]] = e
      return c
    }, {})
  )
}

export function isJsonString(str) {
  try {
    JSON.parse(str)
  } catch (e) {
    return false
  }
  return true
}

export function hexToRgbA(hex, opacity) {
  let c
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("")
    if (c.length == 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = "0x" + c.join("")
    return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')'
  }
  throw new Error('Bad Hex')
}

export function lightenDarkenColor(color, amt) {
  let usePound = false
  if (color[0] === "#") {
    color = color.slice(1)
    usePound = true
  }

  const num = parseInt(color, 16)
  let r = (num >> 16) + amt
  if (r > 255) r = 255
  else if (r < 0) r = 0

  let b = ((num >> 8) & 0x00FF) + amt
  if (b > 255) b = 255
  else if (b < 0) b = 0

  let g = (num & 0x0000FF) + amt
  if (g > 255) g = 255
  else if (g < 0) g = 0

  return (usePound ? "#" : "") + String("000000" + (g | (b << 8) | (r << 16)).toString(16)).slice(-6)
}

export async function makeRequest(url, functionName, method, parameters, body) {
  const token = window.AUTH_TOKEN
  return await new Promise(function (resolve, reject) {
    const xhr = new XMLHttpRequest()

    if (parameters) {
      parameters.plain = true
    } else {
      parameters = { plain: true }
    }

    if ("withCredentials" in xhr) {
      // Most browsers.
      xhr.open(method, url, true)
      xhr.setRequestHeader("Authorization", `Bearer ${token}`)
      xhr.setRequestHeader("function", functionName)
      xhr.setRequestHeader("parameters", JSON.stringify(parameters))
    } else {
      // CORS not supported.
    }

    xhr.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve(xhr.response)
      } else {
        resolve({ exception: xhr.statusText })
      }
    }

    xhr.onerror = function () {
      resolve({ exception: xhr.statusText })
    }

    xhr.withCredentials = false
    xhr.send(JSON.stringify(body))
  })
}

// Deep mergen van object
export function mergeDeep(target, ...sources) {
  if (!sources.length) return target;
  const source = sources.shift();

  if (isObject(target) && isObject(source)) {
    for (const key in source) {
      if (isObject(source[key])) {
        if (!target[key]) Object.assign(target, { [key]: {} });
        mergeDeep(target[key], source[key]);
      } else {
        Object.assign(target, { [key]: source[key] });
      }
    }
  }

  return mergeDeep(target, ...sources);
}

export function sortByKey(array, key, desc) {
  const sorted = array.slice().sort(function (a, b) {
    const dateA = new Date(a[key].split('-').reverse().join('-'));
    const dateB = new Date(b[key].split('-').reverse().join('-'));

    if (desc === "desc") {
      return dateB - dateA;
    } else {
      return dateA - dateB;
    }
  });

  return sorted;
}