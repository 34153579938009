import * as actions from "../actions"
import asyncActions from "../async-actions"

import { getUrl } from "./mangrove"

import moment from "moment"

export function loaderPlus(amount) {
  return async function (dispatch) {
    dispatch({ type: actions.LOADING_STATE, loading: amount === 0 ? 0 : (!amount ? 1 : amount) }) // 0 = 0, undefined = 1, >0 = amount
  }
}

export function loaderMin(amount) {
  return async function (dispatch) {
    dispatch({ type: actions.LOADING_STATE, loading: amount === 0 ? 0 : (!amount ? -1 : -amount) })
  }
}

export function setError(error) {
  return async function (dispatch) {
    // Unieke key hangen aan error
    if (error) { error.key = moment().valueOf() }
    dispatch({ type: actions.SET_ERROR, error: error })
  }
}

export function setAppInfoInState(appinfo) {
  return {
    type: actions.SET_APP_INFO,
    appinfo
  }
}