import asyncActions from "../async-actions"

import packageJson from "../../../package.json"

export function catchMessage(json, dispatch, functionName, successText) {
  let message = "Error"
  let type = "error"
  if (json) {
    if (json.type === "success" || successText) {
      if (successText) {
        message = successText
        type = json.type ? json.type : "info"
      } else {
        return null
      }
    } else {
      if (json.exception) {
        message = json.exception
        type = json.type

        // Voor als we met een bepaalde reden een foutmelding krijgen
        if (json.attributes && json.attributes.reden) {
          message = json.attributes.reden
          type = "success"
        }
      } else {
        return null
      }
    }
  }

  dispatch(asyncActions.setError({
    message: message,
    number: json && json.number,
    function: functionName,
    type: type
  }))
}

export function getAppType() {
  return process.env.REACT_APP_MANGROVE_TYPE
}

export function getWebService() {
  switch (process.env.REACT_APP_MANGROVE_ENV) {
    case "test":
    default:
      return "tsws"
    case "demo":
      return "dmws"
    case "productie":
      return "ws"
  }
}

export function getInternWebService() {
  switch (process.env.REACT_APP_MANGROVE_ENV) {
    case "test":
    default:
      return "mangrove_test.ws"
    case "demo":
      return "mangrove_demo.ws"
    case "productie":
      return "mangrove.ws"
  }
}

export function getServices() {
  const server = getWebService()

  switch (packageJson.availability) {
    case "Extern":
      return `https://${server}.hkvservices.nl`

    case "Intern":
    default:
      // return `http://localhost`
      return "https://ws.hkv.nl"
  }
}

export function getWebServices() {
  const server = getWebService()

  switch (packageJson.availability) {
    case "Extern":
      switch (server) {
        case "tsws":
          return "https://tsgeo.hkvservices.nl"

        case "dmws":
          return "https://dmgeo.hkvservices.nl"

        default:
          return "https://geo.hkvservices.nl"
      }

    case "Intern":
    default:
      switch (server) {
        case "tsws":
          return "https://tsgeo.hkv.nl"

        case "dmws":
          return "https://dmgeo.hkv.nl"

        default:
          return "https://geo.hkv.nl"
      }
  }
}

export function getLocalUploadUrl() {
  return "http://localhost/mangrove.ws/entry.asmx/Upload"
}

export function getLocalUrl(functionName, parameters) {
  parameters.firebaseProvider = "mfa"
  let url = `http://localhost/mangrove.ws/entry.asmx/Call?function=${functionName}&parameters=${encodeURIComponent(JSON.stringify(parameters))}`

  // Als gebruiker is ingelogd, gebruik Execute, zodat we gebruiker kunnen valideren in back-end
  if (window.AUTH_UID || window.AUTH_TOKEN) {
    url = url.replace("/Call?", "/Execute?")

    url += window.AUTH_UID ? `&uid=${window.AUTH_UID}` : "&uid="
    url += window.AUTH_TOKEN ? `&token=${window.AUTH_TOKEN}` : "&token="
  }

  return url
}

export function getUploadUrl() {
  const service = getServices()

  switch (packageJson.availability) {
    case "Extern":
      return `${service}/mangrove.ws/entry.asmx/Upload`

    case "Intern":
    default:
      const internWS = getInternWebService()
      return `${service}/${internWS}/entry.asmx/Upload`
  }
}

export function getUrl(functionName, parameters) {
  parameters.firebaseProvider = "mfa"
  parameters.apptype = getAppType()
  const service = getServices()

  switch (packageJson.availability) {
    case "Extern":
      let url = `${service}/mangrove.ws/entry.asmx/Call?function=${functionName}&parameters=${encodeURIComponent(JSON.stringify(parameters))}`

      // Als gebruiker is ingelogd, gebruik Execute, zodat we gebruiker kunnen valideren in back-end
      if (window.AUTH_UID || window.AUTH_TOKEN) {
        url = url.replace("/Call?", "/Execute?")

        url += window.AUTH_UID ? `&uid=${window.AUTH_UID}` : "&uid="
        url += window.AUTH_TOKEN ? `&token=${window.AUTH_TOKEN}` : "&token="
      }

      return url

    case "Intern":
    default:
      const internWS = getInternWebService()
      return `${service}/${internWS}/entry.asmx/Call?function=${functionName}&parameters=${encodeURIComponent(JSON.stringify(parameters))}`
  }
}