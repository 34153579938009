import { combineReducers } from "redux"
import * as actions from "../actions"

function userinfo(state = null, action) {
  switch (action.type) {
    case actions.SET_AUTH_USER:
      return action.userinfo

    default:
      return state
  }
}

function userdata(state = null, action) {
  switch (action.type) {
    case actions.SET_USERDATA:
      return action.userdata

    default:
      return state
  }
}

function subscription(state = { active: false, token: null }, action) {
  switch (action.type) {
    case actions.SET_SUBSCRIPTION:
      return action.subscription

    default:
      return state
  }
}

export default combineReducers({
  userdata,
  userinfo,
  subscription
})
