import React, { useState } from "react"
import { useTranslation } from "react-i18next"

import asyncActions from "../../../../state/async-actions"

import { makeStyles } from "@mui/styles"

import {
  Button,
  DialogContent,
  DialogTitle,
  FormControl,
  Paper,
  TextField,
  Typography,
  useTheme
} from "@mui/material"

import HKVDialog from "../../../HKVDialog/6.0"

import { doPasswordReset, doSignInWithEmailAndPassword } from "./Functions"

import packageJson from "../../../../../package.json"

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {"Powered by "} <br></br>
      <a href="https://www.hkv.nl/" target="_blank">
        HKV Services Mangrove framework
      </a>
      {" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  )
}

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: `url("${process.env.PUBLIC_URL}/static/images/login.jpg")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
    [theme.breakpoints.up("md")]: {
      width: "50%",
      display: "block"
    },
    [theme.breakpoints.up("lg")]: {
      width: "75%",
      display: "block"
    }
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(8, 4),
    boxSizing: "border-box",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    },
    [theme.breakpoints.up("md")]: {
      width: "50%"
    },
    [theme.breakpoints.up("lg")]: {
      width: "25%"
    }
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(1)
  }
}))

function Login(props) {
  const { dispatch, firebaseUser } = props

  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles(theme)

  const [dialogConfigs, setDialogConfigs] = useState({})
  
  const [userinfo, setUserinfo] = useState({})

  const handleChangeEmail = (event, type) => {
    setUserinfo(prevState => {
      prevState.email = event.target.value
      return { ...prevState }
    })
  }

  const handleKeyUpEmail = (event, type) => {
    // 13 = enter
    if (event.keyCode === 13 && isValid) {
      // Bepalen welke functie we moeten uitvoeren afhankelijk van welk textfield type we komen
      switch (type) {
        case "forgot": handlePasswordRequest(); break;
        default: handleLogin(); break;
      }
    }
  }

  const handleChangePassword = (event) => {
    setUserinfo(prevState => {
      prevState.password = event.target.value
      return { ...prevState }
    })
  }

  const handleKeyUpPassword = (event) => {
    // 13 = enter
    if (event.keyCode === 13) {
      handleLogin()
    }
  }

  const handleLogin = () => {
    // Inloggen met opgegeven credentials
    doSignInWithEmailAndPassword(userinfo.email, userinfo.password, dispatch)
  }

  const openDialog = (key, size, options) => {
    const mergedOptions = { ...options, open: true, size: size ? size : "lg" }

    setDialogConfigs(prevState => {
      prevState[key] = mergedOptions
      return { ...prevState }
    })
  }

  const closeDialog = (key) => {
    setDialogConfigs(prevState => {
      prevState[key] = null
      return { ...prevState }
    })
  }

  const handlePasswordRequest = () => {
    // Functie voor wachtwoord reset
    doPasswordReset(userinfo.email, dispatch)
    closeDialog("password")
  }

  return (
    <React.Fragment>
      <div style={{ display: "flex", height: "100%" }}>
        <div className={classes.image} />
        <Paper square className={classes.paper}>
          <img alt="avatar" src={`${process.env.PUBLIC_URL}/static/images/logo-48.png`} width={40} height={40} />
          <Typography variant="caption" sx={{ marginTop: theme.spacing(0.5) }}>{t("Version")}: {packageJson.version}</Typography>
          <Typography component="h1" variant="h5" sx={{ marginTop: theme.spacing(0.5) }}>
            {t("Log_in")}
          </Typography>
          <FormControl className={classes.form}>
            <TextField
              autoFocus
              autoComplete="email"
              required
              fullWidth
              label={t("Email")}
              variant="outlined"
              sx={{ marginBottom: theme.spacing(2) }}
              onChange={handleChangeEmail}
              onKeyUp={(event) => handleKeyUpEmail(event)}
            />
            <TextField
              required
              fullWidth
              label={t("Password")}
              type="password"
              variant="outlined"
              sx={{ marginBottom: theme.spacing(2) }}
              onChange={handleChangePassword}
              onKeyUp={(event) => handleKeyUpPassword(event)}
            />
            <Button
              fullWidth
              variant="contained"
              sx={{ marginBottom: theme.spacing(2) }}
              onClick={handleLogin}
            >
              {t("Log_in")}
            </Button>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography
                component="a"
                sx={{ cursor: "pointer", textDecoration: "none" }}
                color="primary"
                variant="body2"
                onClick={() => openDialog("password")}
              >
                {t("Forgot_password")}
              </Typography>
            </div>
            <div style={{ padding: theme.spacing(5) }}>
              <Copyright />
            </div>
          </FormControl>
        </Paper>
      </div>
      <HKVDialog config={dialogConfigs.password} onClose={() => closeDialog("password")}>
        <DialogTitle>{t("Forgot_password")}</DialogTitle>
        <DialogContent>
          <Typography>{t("Forgot_password_text")}</Typography>
          <FormControl className={classes.form}>
            <TextField
              autoFocus
              autoComplete="email"
              required
              fullWidth
              size="small"
              label={t("Email")}
              variant="outlined"
              sx={{ marginBottom: theme.spacing(2) }}
              onChange={handleChangeEmail}
              onKeyUp={(event) => handleKeyUpEmail(event, "forgot")}
              value={userinfo.email}
            />
          </FormControl>
          <div className="dialog-actions">
            <Button color="inherit" variant="outlined" className="dialog-button" onClick={() => closeDialog("password")}>
              {t("Cancel")}
            </Button>
            <Button
              disabled={!userinfo.email}
              variant="contained"
              className="dialog-button"
              onClick={handlePasswordRequest}
            >
              {t("Send")}
            </Button>
          </div>
        </DialogContent>
      </HKVDialog>
    </React.Fragment>
  )
}

export default Login