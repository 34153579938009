import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import {
  AppBar,
  Avatar,
  Backdrop,
  Divider,
  Drawer,
  Fab,
  Tooltip,
  Typography,
  useTheme
} from "@mui/material"

import { makeStyles } from "@mui/styles"

import {
  ChevronLeftRounded,
  ChevronRightRounded,
  KeyboardArrowDown,
  KeyboardArrowUp
} from "@mui/icons-material"

import ReactResizeDetector from "react-resize-detector"

import "./HKVDrawer.css"

import packageJson from "../../../../package.json"
import HKVIcon from "../../HKVIcon/1.0/HKVIcon"

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    background: "#f5f5f5",
    boxShadow: "1px 0px 10px rgba(0,0,0,0.3)",
    textAlign: "left",
    padding: theme.spacing(1),
    boxSizing: "border-box",
    [theme.breakpoints.up("xs")]: {
      // width: "85%"
      width: "80%"
    },
    [theme.breakpoints.up("sm")]: {
      width: 280
    },
    [theme.breakpoints.up(1500)]: {
      width: 360
    }
  },
  drawerPaperMini: {
    overflow: "hidden",
    background: "#f5f5f5",
    boxShadow: theme.shadows[1],
    textAlign: "left",
    padding: theme.spacing(1),
    boxSizing: "border-box",
    transition: "none !important",
    borderRight: 0,
    width: 60,
    [theme.breakpoints.down("sm")]: {
      width: 40
    }
  },
  drawerPaperLarge: {
    background: "#f5f5f5",
    boxShadow: "1px 0px 10px rgba(0,0,0,0.3)",
    textAlign: "left",
    padding: theme.spacing(1),
    boxSizing: "border-box",
    [theme.breakpoints.up("xs")]: {
      width: "80%"
    },
    [theme.breakpoints.up("sm")]: {
      width: 560
    },
    [theme.breakpoints.up(1500)]: {
      width: 720
    }
  },
  drawerPaperBottom: {
    background: "#f5f5f5",
    boxShadow: "1px 0px 10px rgba(0,0,0,0.3)",
    textAlign: "left",
    padding: theme.spacing(1),
    height: 280,
    // normaal zit de transition alleen op de transform, maar deze willen we ook op de margin-left en de margin-right
    transition: "transform 225ms cubic-bezier(0, 0, 0.2, 1) 0ms, margin-left 225ms cubic-bezier(0, 0, 0.2, 1) 0ms, margin-right 225ms cubic-bezier(0, 0, 0.2, 1) 0ms !important"
  },
  header: {
    flexDirection: "row",
    padding: theme.spacing(0.5, 1)
  },
  headerimage: {
    display: "flex",
    flexDirection: "column",
    background: "transparent",
    objectFit: "contain",
    margin: theme.spacing(0, -0.25),
    padding: theme.spacing(0.5),
    maxWidth: "100%",
    height: "auto",
    "& svg": {
      color: theme.palette.secondary.main
    },
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
      padding: 0
    }
  },
  drawerButton: {
    cursor: "pointer",
    position: "absolute",
    transition: "225ms cubic-bezier(0, 0, 0.2, 1) 0ms"
  },
  drawerButtonLeft: {
    left: theme.spacing(1),
    top: theme.spacing(1)
  },
  drawerButtonRight: {
    right: theme.spacing(1),
    top: theme.spacing(1)
  },
  drawerButtonBottom: {
    left: theme.spacing(1),
    bottom: theme.spacing(1)
  },
  title: {
    width: "100%",
    alignSelf: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap"
  },
  backdrop: {
    zIndex: 1,
    cursor: "pointer"
  }
}))

function HKVDrawer(props) {
  const { defaultOpen, header, headerButton, headerIcon, image, offsetLeft, offsetTop, onToggle, placement, title, toggleButton, variant, zIndex } = props

  const { t } = useTranslation()
  const theme = useTheme()
  const classes = useStyles(theme)

  const [open, setOpen] = useState(false)
  const [showBackdrop, setShowBackdrop] = useState(window.innerWidth < 600 && defaultOpen)

  // Als defaultOpen is veranderd
  useEffect(() => {
    setOpen(defaultOpen)
  }, [JSON.stringify(defaultOpen)])

  // Als drawer is getoggled
  useEffect(() => {
    drawerStateChanged()
    determineDrawerClass()
    resize()
  }, [JSON.stringify(open)])

  const drawerStateChanged = () => {
    const leafletControls = Array.from(document.getElementsByClassName(`leaflet-${placement}`))

    leafletControls.forEach(leafletControl => {
      if (open && window.innerWidth >= 600) {
        leafletControl.classList.add(placement === "left" ? "moveL" : "moveR")
      } else {
        leafletControl.classList.remove(placement === "left" ? "moveL" : "moveR")
      }
    })

    onToggle(placement, open)
  }

  const resize = () => {
    // Als drawer open is, kijken of window kleiner dan 600px is, zo ja show backdrop
    // Als drawer niet open is, zet backdrop uit
    setShowBackdrop(showBackdrop => {
      if (open) {
        return window.innerWidth < 600
      } else {
        return false
      }
    })
  }

  const determineButtonClass = () => {
    let className = ""
    switch (placement) {
      case "left": className = classes.drawerButtonLeft; break
      case "right": className = classes.drawerButtonRight; break
      case "bottom": className = classes.drawerButtonBottom; break
      default: break
    }

    return `${className} ${classes.drawerButton}`
  }

  const determineDrawerClass = () => {
    const drawerBottom = document.getElementById("drawer-bottom")
    const buttonBottom = document.getElementById("button-bottom")

    switch (placement) {
      case "left":
        if (drawerBottom) {
          if (open) {
            drawerBottom.classList.add("drawerPaperBottomOpenLeft")
            buttonBottom.classList.add("drawerButtonOpenLeft")
          } else {
            drawerBottom.classList.remove("drawerPaperBottomOpenLeft")
            buttonBottom.classList.remove("drawerButtonOpenLeft")
          }
        }
        break

      case "right":
        if (drawerBottom) {
          if (open) {
            drawerBottom.classList.add("drawerPaperBottomOpenRight")
          } else {
            drawerBottom.classList.remove("drawerPaperBottomOpenRight")
          }
        }
        break

      default:
        break
    }
  }

  const renderIcon = () => {
    switch (placement) {
      case "right":
      case "right-mini":
      case "right-large":
        return open ? <ChevronRightRounded /> : <ChevronLeftRounded />

      case "bottom":
        return open ? <KeyboardArrowDown /> : <KeyboardArrowUp />

      case "left":
      default:
        return open ? <ChevronLeftRounded /> : <ChevronRightRounded />
    }
  }

  const determinePaperClass = () => {
    switch (placement) {
      case "bottom":
        return classes.drawerPaperBottom

      case "left-mini":
      case "right-mini":
        return classes.drawerPaperMini

      case "left-large":
      case "right-large":
        return classes.drawerPaperLarge


      case "left":
      case "right":
      default:
        return classes.drawerPaper
    }
  }

  const renderHeader = () => {
    switch (header) {
      case "none":
        return null

      case "img":
      case "image":
        return (
          <React.Fragment>
            <Tooltip title={`${packageJson.longName} - ${t("Version")}: ${packageJson.version}`} placement="right">
              <Avatar src={image} className={classes.headerimage} alt={title} />
            </Tooltip>
            <Divider sx={{ marginTop: theme.spacing(1), borderBottomWidth: 2 }} />
          </React.Fragment>
        )

      default:
        return (
          <AppBar
            className={classes.header}
            position="sticky"
            dir={placement.includes("left") ? "ltr" : (image || title) ? "rtl" : "ltr"}
            elevation={1}
            sx={{ borderRadius: `${theme.shape.borderRadius}px` }}
          >
            {Boolean(image) && <React.Fragment>
              <Tooltip title={`${packageJson.longName} - ${t("Version")}: ${packageJson.version}`} placement="right">
                <Avatar src={image} className={classes.headerimage} alt={title} />
              </Tooltip>
              <Divider sx={{ marginTop: theme.spacing(1), borderBottomWidth: 2 }} />
            </React.Fragment>}
            {Boolean(title) && <Typography variant="h6" sx={{ margin: theme.spacing(0, 1), direction: "ltr" }} className={classes.title} title={title}>{title}</Typography>}
            {headerButton !== false && <Fab
              size="small"
              color="primary"
              onClick={() => setOpen(!open)}
              sx={{ alignSelf: "center", flexShrink: 0, boxShadow: "none" }}
            >
              {renderIcon()}
            </Fab>}
          </AppBar>
        )
    }
  }

  return (
    <ReactResizeDetector onResize={resize}>
      <React.Fragment>
        <Drawer
          id={`drawer-${placement}`}
          anchor={placement.includes("left") ? "left" : "right"}
          variant="persistent"
          classes={{ paper: determinePaperClass() }}
          open={open}
          sx={{
            "& >div": {
              zIndex: zIndex ? zIndex : 1200,
              "&:first-of-type": {
                marginLeft: offsetLeft ? offsetLeft : 0,
                marginTop: offsetTop ? offsetTop : 0,
                // height: offsetTop ? `calc(100% - ${offsetTop})` : "100%"
                height: offsetTop ? "fit-content" : "100%"
              }
            }
          }}
        >
          {renderHeader()}
          {props.children}
        </Drawer>
        {!placement.includes("-mini") && <Backdrop open={showBackdrop} className={classes.backdrop} onClick={() => setOpen(!open)} />}
        {(showBackdrop || toggleButton !== false) && !placement.includes("-mini") && <Fab
          id={`button-${placement}`}
          size="small"
          color="primary"
          className={determineButtonClass()}
          onClick={() => setOpen(!open)}
        >
          {renderIcon()}
        </Fab>}
      </React.Fragment>
    </ReactResizeDetector>
  )
}

export default HKVDrawer