import { combineReducers } from "redux"

import application from "./application"
import database from "./database"
import firebase from "./firebase"

export default combineReducers({
  application,
  database,
  firebase
})