import React, { Suspense } from "react"
import ReactDOM from "react-dom/client"

import { Provider } from "react-redux"
import { applyMiddleware, createStore } from "redux"
import rootReducer from "./state/reducers"
import thunk from "redux-thunk"

import App from "./App"

import * as serviceWorker from "./serviceWorker"

import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles"

import "./index.css"

const theme = createTheme({
  fontFamily: "Roboto, sans-serif",
  spacing: 8,
  palette: {
    primary: {
      main: "#aa2e25",
      contrastText: "#FFFFFF"
    },
    secondary: {
      light: "#F1F1F1",
      main: "#E8E8E8",
      dark: "#DDDDDD"
    }
  },
  components: {
    MuiSlider: {
      styleOverrides: {
        thumb: {
          "&:hover, &.Mui-focusVisible": {
            boxShadow: "0 0 0 6px rgba(0,124,192,0.16)"
          },
          "&.Mui-active": {
            boxShadow: "0 0 0 8px rgba(0,124,192,0.16)"
          }
        }
      }
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            backgroundColor: "rgba(0, 0, 0, 0.08)",
            "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.08)" },
            "&.Mui-focusVisible": { background: "rgba(0, 0, 0, 0.08)" }
          }
        }
      }
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true
      }
    },
    MuiCheckbox: {
      defaultProps: {
        disableFocusRipple: true,
        disableTouchRipple: true,
        disableRipple: true
      }
    }
  }
})

const store = createStore(
  rootReducer,
  applyMiddleware(thunk)
)

const root = ReactDOM.createRoot(document.getElementById("root"))

root.render(
  <Provider store={store}>
    <StyledEngineProvider injectFirst>
      <Suspense fallback="">
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </Suspense>
    </StyledEngineProvider>
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
