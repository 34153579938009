import firebase from "firebase/compat/app"
import "firebase/compat/auth"
import "firebase/compat/database"
import "firebase/compat/firestore"
import "firebase/compat/messaging"

import config from "../config_fithub.json"

const app = firebase.initializeApp(config)

const auth = firebase.auth()
const db = firebase.database()
const firestore = firebase.firestore()

export { auth, db, firestore }
